
import { Component, Vue } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import { BASE_API_URL } from "../../../config";
import Axios from "axios";
import { authHeader } from "../../../services/auth";

@Component({ components: { Multiselect } })
export default class ManageRole extends Vue {
  public taskList: any = [];
  public rolesList: any = [];
  public roleObj: any = {
    tasks: [],
    errorMsg: null,
    taskError: false,
  };
  public isEdit = false
  public problemMsg: any = {};

  public async getRolesList() {
    this.$store.state.tmpcoLoader = true;
    try {
      const response = await Axios.get(
        `${BASE_API_URL}super-admin/roles/getRolesList`,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.rolesList = response.data;
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public async openAddRoleModal() {
    this.$modal.show("addRoleModal");
    this.isEdit = false;
    this.getAllTasksList();
  }

  public async closeRoleModal() {
    await this.$modal.hide("addRoleModal");
    this.taskList = [];
    this.roleObj = {
      tasks: [],
      errorMsg: null,
      taskError: false,
    };
  }

  public async getAllTasksList() {
    this.$store.state.tmpcoLoader = true;
    try {
      const response = await Axios.get(
        `${BASE_API_URL}super-admin/roles/getAllTasksList`,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.taskList = response.data;
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public async addNewRole() {
    this.$store.state.tmpcoLoader = true;
    try {
      if (this.roleObj.tasks.length == 0) {
        this.roleObj.taskError = true;
        this.roleObj.errorMsg = "The Tasks field is required.";
        this.$store.state.tmpcoLoader = false;
        return;
      }

      const response = await Axios.post(
        `${BASE_API_URL}super-admin/roles/addNewRole`,
        this.roleObj,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        await this.$snotify.success("Roles added successfully");
      }
      await this.closeRoleModal();
      await this.getRolesList();
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public async checkProblems(role) {
    try {
      const response = await Axios.post(
        `${BASE_API_URL}super-admin/roles/checkProblems`,
        { id: role.id },
        { headers: authHeader() }
      );
      if (response.status === 201) {
        if (response.data.userData.length > 0) {
          this.problemMsg = `This role is assigned to ${response.data.userName} Processor`;
          this.$modal.show("messageModal");
        } else {
          this.deleteRole(role);
        }
      }
      await this.getRolesList();
    } catch (error) {
      console.log(error);
    }
  }

  public async deleteRole(role) {
    try {
      const response = await Axios.post(
        `${BASE_API_URL}super-admin/roles/deleteRole`,
        { id: role.id },
        { headers: authHeader() }
      );
      if (response.status === 201) {
        await this.$snotify.success("Role Deleted Successfully");
      }
      await this.getRolesList();
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    await this.getRolesList();
  }
}
